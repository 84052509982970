import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import styled from 'styled-components';
import { Flex, Box } from 'grid-styled'
import Form from '../form.js';
import { 
  CenteredBox, 
  Typography,
  Title,
  Bold,
  Close
} from '../styles/styled-system'
import { 
  SuiteMask, 
  SuiteContainer, 
  SuiteSlider, 
  Suite 
} from '../suite.js';

const MapContainer = styled.div`
  height: 70vh;
  width: 100%;
  
  & iframe {
    width: 100%;
    height: 100%;
  }
`

class Trouver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }
  render() {
    const { open } = this.state;
    return (
      <ScrollableAnchor id="nous-trouver">
        <section>
          <Flex bg="beige.light" justifyContent="center" py={4} alignItems="center" flexDirection="column">
            <CenteredBox bg="white">
              <Flex flexWrap="wrap" p={4}>
                <Box width={[1,1/2]}>
                  <Title pb={2} textAlign="left">Nous trouver</Title>
                  <Typography>
                    <Bold>La Grange à outeaux</Bold><br />
                    1590, route de Durtal<br />
                    Lieu-dit La Petite-Grange<br />
                    71470 Montpont-en-Bresse<br />
                    France<br />
                  </Typography>
                </Box>
                <Box width={[1,1/2]} pl={[0,4]} pt={[3,0]}>
                  <Title pb={2} textAlign="left">Nous contacter</Title>
                  <Typography is="p" width={1}>
                    <Bold>Téléphone: </Bold>+33 7 81 09 51 94
                  </Typography>
                  <br />
                  <Typography is="a" href="#" onClick={()=>this.setState({open:true})} fontWeight="semiBold" fontSize={1}>Nous écrire</Typography><br />
                  <Typography is="a" href='https://www.facebook.com/La-Grange-%C3%A0-outeaux-2391394994458001/' target="_blank" fontWeight="semiBold" fontSize={1}>Nous suivre</Typography>
                </Box>
              </Flex>
            </CenteredBox>
            <CenteredBox mt={4}>
              <SuiteMask mb={open?4:0} px={[0,4]} bg='white' open={open}>
                <Close onClick={() => this.setState({open:false})} />
                <SuiteContainer py={4}>
                  <SuiteSlider width={[1,'200%']} flexWrap="noWrap">
                    <Suite pr={[0,'1%']} width={[1,1/2]} display='block'>
                      <Box pt={[0,4]}>
                        <Title pb={2} textAlign="left">Nous écrire</Title>
                      </Box>
                      <Flex flexWrap="wrap" pb={[0,4]}>
                        <Box width={[1,1/2]}>
                          <Typography>
                            Nous vous invitons à utiliser le formulaire suivant pour nous faire part de vos questions ou remarques concernant nos possibilités d'hébergement et d'accompagnement ou les activités à venir. <br />
                            Si vous avez des projets que vous souhaitez partager ici ou avez des envies particulières, n'hésitez pas !
                          </Typography>
                        </Box>
                        <Box width={[1,1/2]} pl={[0,4]} pt={[3,0]}>
                          <Form />
                        </Box>
                      </Flex>
                    </Suite>
                  </SuiteSlider>
                </SuiteContainer>
              </SuiteMask>
            </CenteredBox>
          </Flex>
          <Flex>
            <MapContainer>
            <iframe title="Trouver la granfge à outeaux" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10976.531107507531!2d5.1870429!3d46.5450602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4e578873f709cfe3!2sLa+Grange+%C3%A0+outeaux!5e0!3m2!1sfr!2sch!4v1566197518557!5m2!1sfr!2sch" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
            </MapContainer>
          </Flex>
        </section>
      </ScrollableAnchor>
    )
  }
}
export default Trouver