
import React from 'react'
import { Flex } from 'grid-styled'
import { 
  CenteredBox, 
  Typography,
  Title,
  Bold
} from '../styles/styled-system'
import ScrollableAnchor from 'react-scrollable-anchor'

const Introduction = () => (
  <ScrollableAnchor id="presentation">
    <Flex bg="beige.light" justifyContent="center" is="section">
      <CenteredBox my={4}  color="beige.dark">
        <Title is="h1">Séjour – Découverte – Développement</Title>
        <Typography>
          <Bold>Hébergement et vacances, chambres et table d’hôtes, gîte simple et authentique. </Bold>
        </Typography>
        <Typography>
          <Bold>Possibilité de ressourcement individuel ou en groupe, avec ou sans accompagnement. </Bold>
        </Typography>
        <Typography>
          <Bold>Coaching, supervision, thérapies brèves ou énergétiques (personnes et lieux), présence socio-éducative (handicap)</Bold>
        </Typography>
        <Typography>
          <Bold>Tai-chi Chuan, Qigong.</Bold>
        </Typography>
        <Typography>
          En Bresse bourguignonne, dans une douce nature tranquille, non loin de centres urbains à taille humaine, nous mettons à votre disposition en fonction de vos envies et besoins : diverses possibilités de <Bold>logements</Bold> (gîte ou chambre d’hôte), une <Bold>table d’hôte</Bold> simple, équilibrée et ouverte au végétarisme, <Bold>une salle d'activités à louer pour vos stages et séminaires</Bold>, ainsi qu’à la demande un <Bold>accompagnement professionnel</Bold> sur mesure en consultation ou lors d’un séjour de courte à moyenne durée.
        </Typography>
        <Typography>
          <Bold>La Grange à outeaux</Bold>, fruit d’un désir de rapprochement vers la nature et la tranquillité, partage ses espaces et ses prestations depuis l'<Bold>été 2019</Bold>.
        </Typography>
      </CenteredBox>
    </Flex>
  </ScrollableAnchor>
)
export default Introduction