import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import Helmet from 'react-helmet'
import { Flex } from 'grid-styled'

import { Typography, Link, RichText, Box, CenteredBox, Container } from '../components/styles/styled-system.js';

import Layout from '../components/layout'
import Introduction from '../components/sections/introduction'
import Prestations from '../components/sections/prestations'
import Qui from '../components/sections/qui'
import Grange from '../components/sections/grange'
import Region from '../components/sections/region'
import Trouver from '../components/sections/trouver'
import Liens from '../components/sections/liens'
import Documents from '../components/sections/documents'

const IndexPage = ({data, location}) => {
  // const actus = data.actu.nodes;
  // const evenements = data.evenements.nodes;
  const { host, protocol } = location
  return (
    <Layout>
      <Helmet
        meta={[
          { name: 'og:image',content: `${protocol}//${host}${data.cadre.childImageSharp.fluid.src}` }
        ]}
      />
      <Flex justifyContent="center" flexWrap="wrap">
        <Box width={1} mt={4} position="relative">
          <Img fluid={data.cadre.childImageSharp.fluid} alt="Gite de la grange à Outeaux" />
          { /* 
          { (actus.length || evenements.length) && (
            <Container width={1} position='absolute' bottom="0">
              <Flex justifyContent="center" width={1}>
                <CenteredBox width={[1, 2/3, 1/2]} my={[0,4]}  color="beige.dark">
                  <Flex bg="beige.opaque" px={3} py={[1,2]}>
                    <Box color="black">
                      <Typography color="black" is="h3" fontWeight="semiBold" mb={0}>
                        { evenements.length ? 'Prochain évènement' : 'Actualité' }
                      </Typography>
                      <Flex justifyContent="flex-start" flexWrap="wrap">
                        <RichText render={ evenements.length ? evenements[0].data.accroche.raw : actus[0].data.accroche.raw } />
                        <Typography is="p" ml={2}>
                          <Link to="actualites" color="black" fontWeight="semiBold">
                            Lire la suite
                          </Link>
                        </Typography>
                      </Flex>
                    </Box>
                  </Flex>
                </CenteredBox>
              </Flex>
            </Container>

          )}
          */ }
        </Box>
      </Flex>
      <Introduction />
      <Prestations />
      <Qui />
      <Grange />
      <Region />
      <Trouver />
      <Documents />
      <Liens />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($today: Date!) {
    cadre: file(relativePath: { eq: "cadre-gite.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    evenements: allPrismicActualite(
      filter: {data: {date_event:{ne: null}}} 
      sort: {fields: data___date_event} ) {
      nodes {
        data {
          accroche {
            raw {
              type
              text
              spans {
                type
                start
                end
              }
            }
          }
        }
      }
    }
    actu: allPrismicActualite (
      sort: {fields: data___date}
      filter: {
        data: {
          date_event: {
            eq: null
          }
          date: {
            gte: $today
          }
        }
      }
      ) {
      nodes {
        data {
          accroche {
            raw {
              type
              text
              spans {
                type
                start
                end
              }
            }
          }
        }
      }
    }
  }
`