import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Flex, Box } from 'grid-styled'
import { 
  CenteredBox, 
  Typography,
  Title,
  Bold,
  Ul,
  Close,
  Image,
  Li
} from '../styles/styled-system'

import Img from 'gatsby-image';
// import vincent from '../../images/vincent-gressot-100.jpg'
import plus from '../../images/plus.svg'

import { 
  SuiteMask, 
  SuiteContainer, 
  SuiteSlider, 
  Suite 
} from '../suite.js';

class Qui extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: 0,
      tab: 0
    }
  }
  render() {
    const {tab, open} = this.state;
    return (
      <StaticQuery
        query={graphql`
          query {
            katia: file(relativePath: { eq: "Katia-image-seule-juin-2018-profil.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            vincent: file(relativePath: { eq: "Vincent--image-2-seul-juin-2018-profil.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <ScrollableAnchor id="qui-sommes-nous">
            <Flex bg="beige.light" justifyContent="center" flexWrap="wrap" is="section">
              <CenteredBox mt={4}  color="beige.dark" >
                <Title is="h1" mb={3}>Qui sommes-nous ?</Title>
                <Typography is="h2">
                  <Bold>Katia Biloni et Vincent Gressot</Bold>
                </Typography>
                <Typography>
                  Nous nous sommes rencontrés en 1997 dans la Ville de La Chaux-de-Fonds (1000 m) en Suisse, où nous avons vécu en famille recomposée jusqu’à fin 2018. Professionnellement, nous sommes travailleurs sociaux (éducateur spécialisé et assistante sociale), passionnés par l’humain, la communication, les techniques énergétiques de bien-être et toute démarche personnelle et spirituelle, passant par la connaissance et/ou le mouvement et la méditation.
                </Typography>
                <Typography>
                  Notre « <Bold>projet</Bold> » a naturellement émergé dès le début de notre relation… 
                </Typography>
                <Typography>
                  Nous avons évolué : responsable d’un foyer pour personnes en situation de handicap, tutrice d’adultes puis intervenante auprès de personnes malvoyantes, formateur/-trice d’adultes dans le domaine socio-éducatif, praticiens des thérapies brèves et énergétiques, superviseur et coach, pratiquante de tai-chi chuan, enseignante de qigong…
                </Typography>
                <Typography>
                  Nos recherches ont abouti vers un lieu séduisant, au calme, comportant des bâtiments vivants au milieu de la nature. Nous avons donc quitté les montagnes suisses pour la campagne bressane…
                </Typography>
                <Typography>
                  <Bold>La Grange à outeaux</Bold> a fait ses premiers pas en 2019. La réalisation de ce projet est porteur de nos valeurs et aspirations. Nous souhaitons les partager avec vous, afin de vivre - et vous permettre de vivre - « un peu autrement », au plus près de qui vous êtes.
                </Typography>
                <Flex bg="white" flexWrap="wrap" my={4}>
                  <Box width={[1,1/2]} p={[3,4]} pb={0}>
                    <Img fluid={data.katia.childImageSharp.fluid} alt="Katia Biloni, Professionnelle de la relation d’aide" />
                    <Title mt={3} pb={0} textAlign="left">Katia Biloni</Title>
                    <Ul>
                      <Li fontSize={1}>Professionnelle de la relation d’aide (assistante sociale, tutelle, handicap visuel)</Li>
                      <Li fontSize={1}>Formatrice professionnelle du domaine santé-social</Li>
                      <Li fontSize={1}>Enseignante de Qigong certifiée</Li>
                      <Li fontSize={1}>Pratiquante et enseignante deTai-chi Chuan</Li>
                      <Li fontSize={1}>Floropathe (méthode originale du Dr Bach), praticienne Reiki</Li>
                      <Li fontSize={1}>Proche de la nature et des personnes</Li>
                    </Ul>
                    <Typography fontSize={1} fontWeight="semiBold">
                      Animée par la bienveillance et l’envie de vous offrir un espace de ressourcement.
                    </Typography>
                  </Box>
                  <Box width={[1,1/2]} p={[3,4]} pb={0} bg="beige.dark" color="white" order={[3,1]}>
                    <Img fluid={data.vincent.childImageSharp.fluid} alt="Vincent Gressot, Professionnel de l’accompagnement des personnes en situation de handicap" />
                    <Title mt={3} pb={0} textAlign="left" color="inherit">Vincent Gressot</Title>
                    <Ul>
                      <Li fontSize={1}>Professionnel de l’accompagnement des personnes en situation de handicap</Li>
                      <Li fontSize={1}>Educateur spécialisé (enfants, adolescent, adultes)</Li>
                      <Li fontSize={1}>Responsable d’un foyer d’accueil</Li>
                      <Li fontSize={1}>Formateur en école sociale</Li>
                      <Li fontSize={1}>Thérapeute, coach, consultant (PNL, kinésiologie, régulation émotionnelle TIPI, supervision individuelle ou dans les équipes et les organisations)</Li>
                      <Li fontSize={1}>Professionnel en énergétique : maître Reiki, géobiologue (harmonisation des lieux)</Li>
                    </Ul>
                    <Typography fontSize={1} fontWeight="semiBold">
                      Respectueux de vos besoins et de votre potentiel pour vous accompagner.
                    </Typography>
                  </Box>
                  <Flex is="a" href="#katia" width={[1,1/2]} p={[3,4]} pt={[2,2]} bg="white" color="#a08a7c" onClick={ () => this.setState({tab:0, open:1})} order="2">
                    <Image src={plus} alt="+" width={"1rem"} display="inline-block" pr={2} height='1rem' />
                    <Typography mb={0}>
                      Me connaître un peu plus
                    </Typography>
                  </Flex>
                  <Flex is="a" href="#vincent" width={[1,1/2]} p={[3,4]} pt={[2,2]} bg="beige.dark" color="#c8bba2" onClick={() => this.setState({tab:1, open:1})} order="4">
                    <Image src={plus} alt="+" width={"1rem"} display="inline-block" pr={2} height='1rem' />
                    <Typography mb={0}>
                      Me connaître un peu plus
                    </Typography>
                  </Flex>
                </Flex>
                <SuiteMask mb={open?4:0} px={[0,4]} bg='white' open={open}>
                  <Close onClick={() => this.setState({open:false})} />
                  <SuiteContainer py={4}>
                    <SuiteSlider width={[1,'200%']} ml={`${!tab ? 0:-102}%`} flexWrap="noWrap">
                      <Suite pr={[0,'1%']} isleft="true" width={[1,1/2]} display={[tab===0?'block':'none','block']}>
                      <Typography fontWeight="bold" mb={2}>Katia Biloni</Typography>
                        <Typography>
                          Je suis née en 1969, issue d’une double culture suisse et italienne.
                        </Typography>
                        <Typography>
                          Assistante sociale durant une dizaine d’années dans la tutelle d’adultes aux difficultés psycho-sociales diverses et de mineurs requérants d’asile, j’ai soutenu également durant dix autres années des personnes de tous âges en situation de handicap visuel en collaboration avec des ergothérapeutes, au sein d’un service de consultations spécialisé. J’ai également occupé quelques temps la fonction d’éducatrice auxiliaire auprès de jeunes gens, et exercé durant mes études universitaires comme secrétaire, aide familiale à domicile et aide-soignante dans un établissement pour personnes âgées.
                        </Typography>
                        <Typography>
                          Jusqu'à l'été 2019, les sept dernières années de mon parcours professionnel en Suisse se sont déployées en tant qu'enseignante-formatrice des branches professionnelles aux apprentis (-es) socio-éducatifs (-ves) du canton de Neuchâtel.
                        </Typography>
                        <Typography>
                          En parallèle à la relation d’aide professionnelle et à la pédagogie, mon chemin s’est dirigé vers des disciplines énergétiques et le mouvement plus doux que les sports que je pratiquais assidument. Je suis en mesure d’offrir un partage au travers de soins avec le Reiki et les Fleurs de Bach, mais aussi dans la présence au corps avec le Tai-chi Chuan et le Qigong. Pratiquante de Tai-Chi Chuan depuis 2002, j’enseigne parallèlement le Qigong depuis 2015 (formation complète avec l’Institut Européen de Qigong Yang Shen).
                        </Typography>
                        <Typography>
                          J’ai à cœur un accompagnement de qualité, ainsi qu’un partage attentif, bienveillant et non-jugeant, fait à la fois de distance et de proximité avec tout être humain en quête d’authenticité et d’équilibre.
                        </Typography>
                        <Typography>
                          C’est avec mon compagnon de vie, après nous être occupés durant plus de vingt ans d’un lieu de vie familial que je me réjouis de cette nouvelle aventure de La Grange à outeaux en vous offrant ce que j’estime avoir de meilleur à partager, à savoir : la nature , le mouvement, la relation à l’autre et le retour à soi-même, le tout en conscience
                        </Typography>
                      </Suite>
                      <Suite pl={[0,'1%']} width={[1,1/2]} display={[tab===1?'block':'none','block']}>
                        <Typography fontWeight="bold" mb={2}>Vincent Gressot</Typography>
                        <Typography>
                          Je suis né en 1961 à Genève mais je me considère comme citoyen du monde. J’ai aimé voyager et une partie de mon cœur est en Inde.
                        </Typography>
                        <Typography>
                          Passionné par la compréhension des différentes facettes de l'être humain j'ai une formation de base d'éducateur spécialisé (HES, Haute Ecole Sociale) et j'ai  exercé dans différentes institutions pendant plus de 30 ans. Tout d'abord auprès d'enfants et d'adolescents en difficultés sociales puis avec des adultes souffrant de troubles psychiatriques et de retard mental, en tant que responsable de foyer. 
                        </Typography>
                        <Typography>
                          Formateur, thérapeute et accompagnant depuis de nombreuses années je crois que chaque être humain a un potentiel d'évolution, d'amélioration de son quotidien et c'est dans cet état d'esprit que j'accompagne chaque personne qui souhaite se libérer de certains comportements, schémas de vie, émotions limitantes; qui aspire à entreprendre une démarche pour faire le point sur sa vie et élaborer de nouveaux projets ; qui recherche un équilibre mental ou corporel ; qui souhaite améliorer sa communication dans ses relations professionnelles, sociales, familiales, scolaires ou de couple ; qui désire développer ses capacités d'apprentissage et d'épanouissement personnel sur différents plans de vie (physique, matériel, relationnel, intellectuel, spirituel...). 
                        </Typography>
                        <Typography>
                          Je vous propose donc diverses possibilités si vous cherchez à trouver de nouvelles options face à vos propres difficultés quotidiennes. Mon accompagnement se veut respectueux de vos besoins et de votre potentiel. Dans le courant des thérapies brèves, mes approches principales sont : la régulation émotionnelle (TIPI), la kinésiologie, le Reiki, la programmation neurolinguistique (PNL), le coaching de vie. Je pratique également la supervision individuelle (analyse de pratique), comme intervenant dans les équipes. J'interviens aussi en géobiologie (harmonisation de lieux).
                        </Typography>
                      </Suite>
                    </SuiteSlider>
                  </SuiteContainer>
                </SuiteMask>
              </CenteredBox>
            </Flex>
          </ScrollableAnchor>
        )} />
      )
    }
  
}
export default Qui