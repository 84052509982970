import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Flex } from 'grid-styled'
import { 
  CenteredBox, 
  Typography,
  Title,
  Ul,
  Li
} from '../styles/styled-system'

const Prestations = () => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicSectionLiens {
          nodes {
            data {
              body {
                primary {
                  nom
                }
                items {
                  titre
                  description
                  lien {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <ScrollableAnchor id="amis">
        <Flex justifyContent="center" flexWrap="wrap" is="section">
          <CenteredBox my={4}  color="beige.dark">
            <Title is="h1">Amis et partenaires</Title>
            { data.allPrismicSectionLiens.nodes[0].data.body.map(({primary,items}, index)=>(
              <React.Fragment key={index}>
                <Typography is="h3" fontWeight="semiBold">
                  {primary.nom}
                </Typography>
                { items.length && (
                  <Ul>
                  { items.map(({titre, lien, description}, index2)=>{
                    return (
                      <Li key={`${index}${index2}`}>
                        <Typography is="a" href={lien.url} fontWeight="semiBold" fontSize={1}>{ titre }</Typography><br />
                        { description }
                      </Li>
                    )
                  }) }
                  </Ul>
                )
              }
              </React.Fragment>
            ))}            
          </CenteredBox>
        </Flex>
      </ScrollableAnchor>
    )
  } />
)
export default Prestations