import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Flex, Box } from 'grid-styled'
import { 
  CenteredBox, 
  Typography,
  Title,
  Ul,
  Li
} from '../styles/styled-system'

const Prestations = () => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicSectionDocuments {
          nodes {
            data {
              body {
                primary {
                  nom {
                    text
                  }
                }
                items {
                  document {
                    document {
                      __typename
                      ...on PrismicTarifs {
                        data {
                          nom
                          fichier {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      console.log(data);
      return (
        <ScrollableAnchor id="documents">
          <section>
            <Flex bg="beige.light" justifyContent="center" py={4} alignItems="center" flexDirection="column">
              <CenteredBox bg="white">
                <Flex flexWrap="wrap" p={4}>
                  <Box width={1}>
                    <Title is="h1">Documents utiles</Title>
                    { data.allPrismicSectionDocuments.nodes[0].data.body.map(({primary, items}, index)=>(
                        <React.Fragment key={index}>
                          <Typography is="h3" fontWeight="semiBold">
                            { primary.nom.text }
                          </Typography>
                          { items.length && (
                            <Ul>
                              { items.map(({document}, index2)=>(
                                <Li key={`${index}${index2}`}>
                                  <Typography is="a" href={document.document[0].data.fichier.url} fontWeight="semiBold" fontSize={1}>{document.document[0].data.nom}</Typography><br />
                                  {document.document[0].data.description}
                                </Li>
                              ))}
                            </Ul>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </Box>
                </Flex>
              </CenteredBox>
            </Flex>
          </section>
        </ScrollableAnchor>
      )
    }
  } />
)
export default Prestations