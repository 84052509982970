import styled from 'styled-components'
import tag from 'clean-tag'
import { themeGet, display } from 'styled-system'
import { Flex, Box } from 'grid-styled'

export const SuiteMask = styled(Box)`
  position: relative;
  overflow: hidden;
  transition: all .5s ${themeGet('transition.ease', 'ease')};

  @media (max-width: ${themeGet('breakpoints.0')}) {
    position: fixed;
    width: 100%;
    height: 100vh;
    padding: ${themeGet('space.4', 20)}px;
    background: ${themeGet('colors.beige.dark', 'black')};
    top: 0;
    left: 0;
    z-index: 200;
    transform: translateX(${props => props.open ? '0':'100'}%);
  }

  @media (min-width: ${themeGet('breakpoints.0')}) {
    max-height: ${props => props.open ? 1000 : 0}px;
  }
`
SuiteMask.defaultProps = {
  blacklist: tag.defaultProps.blacklist.concat(['open'])
}

export const SuiteContainer = styled(Box)`
  position: relative;
  overflow: hidden;

  @media (max-width: ${themeGet('breakpoints.0')}) {
    overflow-y: scroll;
    background: white;
    width: 100%;
    height: 100%;
    padding: ${themeGet('space.4', 10)}px;
  }
`

export const SuiteSlider = styled(Flex)`
  transition: all .5s ${themeGet('transition.ease', 'ease')};

  @media (max-width: ${themeGet('breakpoints.0')}) {
    margin-left: 0!important;
  }
`

export const Suite = styled(Box)`
  ${display};
  position: relative;

  @media (min-width: ${themeGet('breakpoints.0')}) {
    &:before {
      content: '';
      position: absolute;
      top: -${themeGet('space.4', '20')}px;
      left: ${props => props.isleft ? '25%' : '75%'};
      transform: translateX(-50%);
      width: 0px;
      height: 0px;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid ${themeGet('colors.beige.light', 'white')};
    }
  }
`
Suite.defaultProps = {
  blacklist: tag.defaultProps.blacklist.concat(['isleft'])
}