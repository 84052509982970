import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import ScrollableAnchor from 'react-scrollable-anchor'
import Lightbox from 'react-images';

import { Flex, Box } from 'grid-styled'
import { 
  CenteredBox, 
  Typography,
  Image,
  Title
} from '../styles/styled-system'

const GaleryA = styled.a`
  position: relative;
  display: block;
  cursor: pointer;
`
const GaleryName = styled(Flex)`
  background: rgba(0,0,0,.6);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  transition: all .5s ${themeGet('transition.ease', 'ease')};
  &: hover {
    opacity: 0;
  }
`

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareOpen: false,
      anchorEl: null,
      isOpen: false,
      currentImage: 0
    };
  }

  gotoPrevLightboxImage() {
    const { currentImage } = this.state;
    this.setState({ currentImage: currentImage - 1 });
  }

  gotoNextLightboxImage() {
    const { currentImage } = this.state;
    this.setState({ currentImage: currentImage + 1 });
  }

  openLightbox(event) {
    event.preventDefault();
    const { photos } = this.props;
    if (photos.length > 0) {
      this.setState({ isOpen: true });
    } else {
      alert('Cette galerie est vide, revenez plus tard');
    }
  }

  closeLightbox() {
    this.setState({ isOpen: false });
  }

  render() {
    const { photos, gachette, nom } = this.props;
    return (
      <GaleryA href={photos.length > 0 ? photos[0].source : '#noGalerie'} onClick={e => this.openLightbox(e)}>
        {/*<Img fluid={gachette} alt={`Galerie: ${nom}`} />*/}
        <Image width={1} src={gachette.url} alt={gachette.alt ? gachette.alt : `Galerie: ${nom}`} />
        <GaleryName alignItems="center" justifyContent="center">
          <Title is="span" color="white">{nom}</Title>
        </GaleryName>
        { photos.length > 0 && 
          <Lightbox 
            images={photos.map(({photo})=>({src:photo.url, caption:photo.alt}))} 
            backdropClosesModal
            currentImage={this.state.currentImage}
            isOpen={this.state.isOpen}
            onClickPrev={() => this.gotoPrevLightboxImage()}
            onClickNext={() => this.gotoNextLightboxImage()}
            onClose={() => this.closeLightbox()}
          />
        }
      </GaleryA>
    )
  }
}
const Grange = () => (
  <StaticQuery
    query={graphql`
      query {
        grange: file(relativePath: { eq: "Outeaux-grange.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ambiance: file(relativePath: { eq: "flux-image-1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chambres: file(relativePath: { eq: "flux-image-2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        salles: file(relativePath: { eq: "flux-image-3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        travaux: file(relativePath: { eq: "flux-image-4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galeries: allPrismicGalerie {
          nodes {
            data {
              titre {
                text
              }
              vignette {
                url
                alt
              }
              photos {        
                photo {
                  alt
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <React.Fragment>
          <ScrollableAnchor id="la-grange">
            <Flex justifyContent="center" flexWrap="wrap" is="section">
              <CenteredBox my={4}  color="beige.dark">
                <Title is="h1">La grange</Title>
                <Typography mb={3}>
                  Ancienne longère bressane à colombages de 42 m, la Grange a été réhabilitée et munie de deux ouvertures vers le ciel offrant lumière et espace : <strong>les outeaux</strong>. 
                </Typography>
                <Typography mb={4}>
                  L’ensemble des rénovations s’est voulu respectueux du cadre naturel et de l’histoire des matériaux et des lieux, tout en visant une accessibilité facilitée pour les personnes à mobilité réduite ou malvoyantes/aveugles. 
                </Typography>
                <Img fluid={data.grange.childImageSharp.fluid} alt="La grange" />
              </CenteredBox>
            </Flex>
          </ScrollableAnchor>
          <ScrollableAnchor id="galeries">
            <Flex bg="beige.light" justifyContent="center" flexWrap="wrap" is="section">
              <CenteredBox mt={4} mb={3}  color="beige.dark">
                <Title>Galeries</Title>
                <Flex flexWrap="wrap" justifyContent="space-between">
                  { data.galeries.nodes.map(({data},index)=> {
                    const padding = {[`${index%2?'pl':'pr'}`]:[0,1]};
                    return (
                      <Box width={[1,1/2]} pb={2} {...padding} key={index}>
                        <Gallery gachette={data.vignette} photos={data.photos} nom={data.titre.text} />
                      </Box>
                    )
                  }) }
                </Flex>
              </CenteredBox>
            </Flex>
          </ScrollableAnchor>
        </React.Fragment>
      )
    }
  } />
)
export default Grange