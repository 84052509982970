import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'

import { Flex } from 'grid-styled'
import { 
  CenteredBox, 
  Typography,
  Bold,
  Title
} from '../styles/styled-system'

const Region = () => (
  <ScrollableAnchor id="la-region">
    <Flex justifyContent="center" flexWrap="wrap" is="section">
      <CenteredBox my={4}  color="beige.dark">
        <Title is="h1">La région</Title>
        <Typography>
          <Bold>La Grange à outeaux</Bold> est un lieu idéal pour séjourner, se ressourcer ou encore se balader en rayonnant dans la magnifique région sud bourguignonne de Saône et Loire, jouxtant l’Ain et le Jura.
        </Typography>
        <Typography>
          Les paysages s’ouvrent sur des champs cultivés, des prés et de nombreux étangs et rivières. L’élevage de bovins et des fameuses volailles de Bresse donnent à la région un air de campagne d’antan, parsemée d’architectures typiques : longères bressanes (longues fermes à colombages), petits châteaux, cheminées sarrasines, moulins à eau.
        </Typography>
        <Typography>
          Les activités de loisirs y sont simples et variées : vélotourisme, voie verte , VTT dans le Revermont, marche, baignade – bassins naturels ou piscines, croisières, excursion au Lac de Vouglan, pêche, cinéma, canoë-kayak, tennis, pétanque, karting au circuit de Bresse, balade à cheval, écomusées, marchés, brocantes, etc.
        </Typography>
        <Typography>
          Quelques villes proches à visiter : <Bold>Louhans</Bold> (grand marché, arcades, hôtel-dieu, apothicairerie), <Bold>Chalon-sur-Saône</Bold> (culture, arts de la rue), <Bold>Mâcon</Bold> (shopping, musées, cathédrale), <Bold>Lons-le-Saunier</Bold> (arcades, lacs, Jura, événements), <Bold>Tournus</Bold> (centre historique), <Bold>Bourg-en-Bresse</Bold> (ville ancienne, productions locales, Monastère de Brou, nature), <Bold>Cluny</Bold> (histoire, religion), etc. 
        </Typography>
        <Typography>
          La gastronomie locale est généralement bien appréciée des touristes avec notamment le fameux poulet de Bresse ou les cuisses de grenouilles et les vins issus des proches vignobles du Mâconnais, de la Bourgogne, du Beaujolais et du Jura.
        </Typography>
        <Typography>
          La Grange à outeaux suit quant à elle un mouvement vers une cuisine naturelle à tendance végétarienne, aux parfums asiatiques et méditerranéens, comme différents producteurs, distributeurs et restaurateurs actuels des alentours.
        </Typography>
      </CenteredBox>
    </Flex>
  </ScrollableAnchor>
)
export default Region