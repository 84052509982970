import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import ScrollableAnchor from 'react-scrollable-anchor'
import { Flex } from 'grid-styled'
import { 
  CenteredBox, 
  Box, 
  Typography,
  Title,
  Ul,
  Li,
  Link,
  Button
} from '../styles/styled-system'

const Prestations = () => (
  <StaticQuery
    query={graphql`
      query {
        paysage: file(relativePath: { eq: "la-grange-paysage.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tarifs: prismicTarifs {
          data {
            nom
            fichier {
              url
            }
          }
        }
      }
    `}
    render={data => (
      <ScrollableAnchor id="prestations">
        <Flex justifyContent="center" flexWrap="wrap" is="section">
          <CenteredBox my={4}  color="beige.dark">
            <Title is="h1">Prestations</Title>
            <Typography is="h2" fontWeight="semiBold">
              Accessibilité pour personnes à mobilité réduite PMR
            </Typography>
            <Typography is="h3" fontWeight="semiBold">
              Hébergement
            </Typography>
            <Ul>
              <Li>En gîte simple et authentique (4 à 6 personnes) </Li>
              <Li>En chambre individuelle, double ou pour plusieurs personnes</Li>
            </Ul>
            <Typography is="h3" fontWeight="semiBold">
              Table d’hôtes
            </Typography>
            <Ul>
              <Li>Cuisine simple, végétarienne ou non, régimes spéciaux sur demande</Li>
              <Li>Événements culinaires</Li>
              { /* <Li>Événements culinaires (consulter les <Link to="/actualites" color="inherit">actualités</Link>)</Li> */ }
            </Ul>
            <Typography is="h3" fontWeight="semiBold">
              Disposition de locaux
            </Typography>
            <Ul>
              <Li>Magnifique salle d'activités lumineuse d'env. 85 m2 pour vos séminaires et stages (avec vestiaire, sanitaires et tables et chaises  sur demande pour 25 personnes)</Li>
            </Ul>
            <Typography is="h3" fontWeight="semiBold">
              Accompagnement
            </Typography>
            <Ul>
              <Li>Aide socio-éducative professionnelle pour personnes en situation de handicap (formulaire de renseignements obligatoire) : présence, activités de la vie quotidienne, ateliers, excursions </Li>
              <Li>Consultations/bien-être : coaching, supervision professionnelle (analyse de pratique), PNL, Tipi (régulation émotionnelle), kinésiologie, Reiki, élixirs floraux (Dr Bach), géobiologie</Li>
              <Li>Ressourcement-mouvement : Tai-chi Quan, Qigong</Li>
            </Ul>
            <Typography is="h3" fontWeight="semiBold">
              Formations
            </Typography>
            <Ul>
              { /* <Li>Séminaires à thème organisés et conduits par nos soins ou d’autres intervenants (consulter <Link to="/actualites" color="inherit">les actualités</Link>)</Li> */ }
              <Li>Séminaires à thème organisés et conduits par nos soins ou d’autres intervenants</Li>
            </Ul> 
            { data.tarifs.data && (
              <Flex justifyContent="center">
                <Button is="a" mt={3} mb={3} fontSize={2} href={data.tarifs.data.fichier.url} target="_blank">
                  Consulter nos tarifs
                </Button>
              </Flex>
            )}
          </CenteredBox>
          <Box width={1}>
            <Img fluid={data.paysage.childImageSharp.fluid} alt="La Grange à Outeaux, prestations" />
          </Box>
        </Flex>
      </ScrollableAnchor>
    )
  } />
)
export default Prestations