import React from 'react'
import { Formik, Form } from 'formik';
import { setLocale, object, string } from 'yup'
import Textarea from 'react-textarea-autosize';

import { Flex } from 'grid-styled'
import { 
  Box, Typography, ErrorMessage, Field, Button
} from './styles/styled-system'

setLocale({
  mixed: {
    // eslint-disable-next-line
    required: "Le champ ${path} est requis"
  }
})

const schema = object().shape({
  trap: string(),
  nom: string().required(),
  prenom: string().required(),
  contact: string().required(),
  tel: string().required(),
  message: string().required()
});

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}
class contactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false
    }
  }
  render() {
    const formName = 'contact-grangeaouteaux';
    return (
      <div>
        <Formik
          initialValues={{ 
            trap: '',
            nom: '', 
            prenom: '',
            contact: '',
            tel: '',
            message: ''
          }}
          validateOnBlur={false}
          validationSchema={schema}
          onSubmit={(values, formikBag) => {
            setTimeout(() => {
              // alert(JSON.stringify(values, null, 2));
              fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": formName, ...values })
              })
                .then(() => {
                  formikBag.resetForm();
                  this.setState({sent: true});
                  setTimeout(() => this.setState({sent:false}), 5000);
                })
                .catch(error => alert("Une erreur est survenue, veuillez réessayer."));   
              formikBag.setSubmitting(false);
            }, 400);
          }}
        >
          {({
            setFieldValue,
            initialValues,
            isSubmitting,
            /* and other goodies */
          }) => {
            return (
              <Form
                name={formName} 
                data-netlify="true" 
                netlify-honeypot="trap" 
                action="/" >
                <Flex flexWrap="wrap" justifyContent="space-between">
                  <Box style={{display:"none"}}>
                    <label>Ne pas remplir: <Field type="email" name="trap" /></label>
                  </Box>
                  <Box width={49/100} mb={1}>
                    <Field type="text" name="prenom" placeholder="Prénom" />
                    <ErrorMessage name="prenom" />
                  </Box>
                  <Box width={49/100} mb={1}>
                    <Field type="text" name="nom" placeholder="Nom" />
                    <ErrorMessage name="nom" />
                  </Box>
                  <Box width={1} mb={1}>
                    <Field type="text" name="contact" placeholder="e-mail" />
                    <ErrorMessage name="contact" />
                  </Box>
                  <Box width={1} mb={1}>
                    <Field type="text" name="tel" placeholder="Téléphone" />
                    <ErrorMessage name="tel" />
                  </Box>
                  <Box width={1} mb={1}>
                    <Field 
                      is={Textarea} 
                      name="message" 
                      onChange={({target}) => {
                        setFieldValue('message', target.value)
                      }}
                      placeholder="Message" 
                      minRows={4} 
                      defaultValue={initialValues.message} />
                    <ErrorMessage name="message" />
                  </Box>
                  <Box width={1}>
                    <Button is="button" type="submit" disabled={isSubmitting}>
                      Envoyer
                    </Button>
                    { this.state.sent && 
                      <Typography pt={2}>
                        <i>Votre message nous est bien parvenu, nous vous en remercions 
                        et y répondrons dès que possible</i>
                      </Typography>
                    }
                  </Box>
                </Flex>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

export default contactForm;

